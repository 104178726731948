@import '../../index';

@mixin user-select($user-select: none text auto contain all) {
    user-select: $user-select;
    -moz-user-select: $user-select;
    -webkit-user-select: $user-select;
    -ms-user-select: $user-select;
}

.steps-container {
    display: flex;
    width: 100%;
    justify-content: center;

    .steps {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 500px;
        margin-left: 2rem;

        .step {
            position: relative;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            margin-right: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ef4e31;
            font-weight: bold;
            background: #fff;
            color: #ef4e31;
            z-index: 1;
            @include user-select(none);

            &:last-child {
                margin-right: 0;
            }

            &.active {
                background: #ef4e31;
                color: #fff;
                border: none;
            }

            &.current {
                background: #fff;
                color: #ef4e31;
                border: none;
            }
        }

        .step-bar {
            position: absolute;
            height: 2px;
            width: 100%;
            background: #ef4e31;
            z-index: 0;
        }
    }
}
