@import '../../index';

.templates__template {
    background-color: #e7eaec;
    height: 250px;
}

.templates__split-button-wrapper {
    display: inline-block;
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
    .templates__view-user-guide {
        border-radius: 0;
        white-space: nowrap;
    }
    .templates__download-user-guide {
        border-radius: 0;
    }
}

.templates__button-icon {
    margin-top: -3px;
}
