// @import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Shared Misc
# Modals
# Header
# Footer
# Login
# Admin
# Admin/Admins
# Admin/Organizations
# Admin/Users
# Admin/Windows
# User
# User/Submissions

--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Shared Misc
--------------------------------------------------------------*/

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.navbar-collapse {
    .navbar-nav {
        .active-nav {
            color: rgba(0, 0, 0, 0.7);
            background-color: #e7eaec;
            border-radius: 0.25rem;
        }
    }
}

.nav-dropdown-left {
    .dropdown-menu {
        left: inherit;
        right: 0;
    }
}

.positionToolTip {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.secureTableContainer {
    position: relative;

    .secureTable {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.7);
    }

    table {
        filter: blur(8px);
        -webkit-filter: blur(8px);
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    }
}

.fileUpload {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.fileName {
    position: absolute;
    bottom: 0;
}

.no-width {
    max-width: 0;
}

/*--------------------------------------------------------------
# MODALS
--------------------------------------------------------------*/

.calendar-rows {
    display: flex;
    flex-wrap: nowrap;
}

.modal-header {
    justify-content: flex-start;
}

.organization-container {
    overflow-y: auto;
    border: solid 1px #dfdfdf;
    height: 150px;
    padding: 0.5rem;
}

.organizations-enabled {
    width: 80%;
}

.file-upload-tooltip {
    position: relative;
    z-index: 2;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.navbar {
    border-bottom: 2px solid #ef4e31;
}

.navbar-text {
    display: inline-block;
    padding-top: 1.25rem;
    padding-bottom: 0.5rem;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Login
--------------------------------------------------------------*/
.login-container {
    text-align: center;
    width: 50%;
    padding-top: 6rem;
}
.input-box {
    width: 30%;
    text-align: center;
}

/*--------------------------------------------------------------
# Admin
--------------------------------------------------------------*/
.dropdown-rows {
    align-items: center;
}

.dropdown-labels {
    margin-bottom: 0;
}
.dropdown .dropdown-menu {
    max-height: 250px;
    overflow: scroll;
}

/*--------------------------------------------------------------
# Admin/Admins
--------------------------------------------------------------*/
.admins-table-headers {
    width: 25%;
}

/*--------------------------------------------------------------
# Admin/Organizations
--------------------------------------------------------------*/

.org-name-table-header {
    width: 80%;
}

/*--------------------------------------------------------------
# Admin/Users
--------------------------------------------------------------*/
.users-table-headers {
    width: 20%;
}

/*--------------------------------------------------------------
# Admin/Windows
--------------------------------------------------------------*/
.date-container {
    align-items: center;
}

.accordion-windows {
    width: 100%;

    .card {
        .collapse {
            border-top: 1px solid #dfdfdf;
        }
    }
}

.date-container {
    .archive-button-column {
        text-align: end;
    }
}

.archived-org-enabled {
    width: 100%;
}

.org-buttons {
    display: flex;
    justify-content: flex-end;
}
/*--------------------------------------------------------------
# User
--------------------------------------------------------------*/
.warning-message {
    color: red;
}

.section-1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
}

.section-1-tooltip {
    display: flex;
    justify-content: flex-end;
}

.section-2 {
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 2rem;
}

.section-3 {
    display: flex;
    justify-content: center;
    position: relative;
}

.section-3-tooltip {
    display: flex;
    justify-content: flex-end;
}

.section-4 {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
}

.section-4-tooltip {
    display: flex;
    justify-content: flex-end;
}

.incorrect-tables {
    width: 70%;
}

.select-file-btn {
    width: 50%;
}

.file-error-icon {
    color: #ef4e31;
    height: 4rem;
    width: 4rem !important;
}

select.mb-3.user-modal.form-control {
    background-color: #6c757d;
    border-color: #6c757d;
    color: white;
    width: 38;
}

/*--------------------------------------------------------------
# User/Submissions
--------------------------------------------------------------*/
